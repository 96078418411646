import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Link, Trans,useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

const LeftImage = styled((props) =><div {...props}><StaticImage src="../images/scatoirecolor.jpg" alt="Yours truly"/></div>)`
width:33%;
float:left;
`;

const AboutPage = () => {
  const {t} =useTranslation()
  return (
    <>
      <Layout>
        <h1>{t("AboutMe.H1")}</h1>
        <LeftImage src="../images/scatoirecolor.jpg" />
        <Trans i18nKey="AboutMe.P">
        </Trans>
      </Layout>
    </>
  );
};

export default AboutPage;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;